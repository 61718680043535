import { useNavigate } from 'react-router';
import { useToastMessage } from '../../../hooks/useQueryHelper';
import useTestEmailChannel from '../../../hooks/channel-query/useTestEmailChannel';
import { Button } from '@squantumengine/horizon';
import useCampaignParams from '../../../hooks/useCampaignParams';

interface ActionButtonProps {
  emails: string[];
}

function ActionButton({ emails }: ActionButtonProps) {
  const { toastMessage } = useToastMessage();
  const { channelId, channelType, campaignType } = useCampaignParams();

  const navigate = useNavigate();
  const { mutate } = useTestEmailChannel(channelId, toastMessage);

  const onCancel = () => navigate(`/campaign/${campaignType}/${channelType}/${channelId}`);

  return (
    <>
      <Button data-testid="btn_cancel" variant="text" onClick={onCancel}>
        Batalkan
      </Button>
      <Button onClick={() => mutate(emails)} disabled={!emails.length} data-testid="btn_publish">
        Terbitkan
      </Button>
    </>
  );
}

export default ActionButton;
