import Papa from 'papaparse';
import { getErrorMessage } from './error';

export interface CsvRow {
  [key: string]: string | undefined;
}

export const convertFileToBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

export const getFileExtension = (filename: string) => {
  const ext = /^.+\.([^.]+)$/.exec(filename);
  return ext == null ? '' : ext[1];
};

export const getBinaryFromFile = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.addEventListener('load', () => resolve(reader.result));
    reader.addEventListener('error', (err) => reject(err));

    reader.readAsBinaryString(file);
  });
};

export async function validateContactCSV(
  file: File,
  channelHeader: string,
  callback: (errorList: string[]) => void
) {
  const errorMessageSet = new Set<string>();

  let totalRows = 1;
  let isFirstRow = true;

  const selectedChannelHeader = channelHeader;

  Papa.parse<CsvRow>(file, {
    header: true,
    skipEmptyLines: true,
    step: function (row, parser) {
      try {
        if (totalRows > 100_000) {
          errorMessageSet.add('Maksimal 100.000 baris data kontak');
          parser.abort();
        }

        if (isFirstRow) {
          const rowHeaders = Object.keys(row.data);

          rowHeaders.forEach((header, i) => {
            if (i === 0 && header !== selectedChannelHeader)
              errorMessageSet.add(`Header yang digunakan tidak sesuai channel.`);

            if (!/^[a-z]+(?:_[a-z]+)*$/.test(header))
              errorMessageSet.add('Header yang digunakan tidak sesuai format.');
          });
          isFirstRow = false;
        }

        const rowValues = Object.values(row.data);
        const isEmptyValue = rowValues.some((item) => !String(item)?.trim());

        if (isEmptyValue) {
          errorMessageSet.add('Terdapat kolom yang kosong.');
          parser.abort();
        }

        totalRows++;
      } catch (error) {
        errorMessageSet.add(
          'Terjadi kesalahan. Pastikan berkas yang anda unggah telah memenuhi ketentuan.'
        );
        parser.abort();
      }
    },
    complete: function () {
      callback(Array.from(errorMessageSet));
    },
    error: function (error) {
      callback([...Array.from(errorMessageSet), getErrorMessage(error)]);
    }
  });
}
