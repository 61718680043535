import { useMemo, useState } from 'react';
import EmailContent from '../../../components/email-content';
import { parseTemplateWidth } from '../../../components/rich-text/editor/hooks/useInitialHtml';
import { useGenerateHtmlContent } from '../../../hooks/useGenerateHtmlContent';
import {
  CampaignTypeEnum,
  ChannelStatusEnum,
  EmailDetailInterface
} from '../../../shared/automated-campaign.interface';
import ActionButton from './action-button';
import LeftSection from '../general/left-section';
import { precodingArrayToMap } from '../../../utils/precoding';
import { parsePrecodingInString } from '../../../utils/lexical';
import { useGetEmailChannelDetail } from '../../../hooks/automated-campaign-query';
import { ChannelKeyEnum } from '../../../shared/master-api.interface';
import { emptyState } from '../../../hooks/useCreateEmptyEditor';
import { TextField } from '@squantumengine/horizon';
import CampaignContentHeader from '../../../components/campaign-content-header';
import { attachmentListToMap } from '../../../components/rich-text/plugins/AttachmentPlugin/attachment-plugin.helper';
import Loader from '../../../components/loader';
import useCampaignParams from '../../../hooks/useCampaignParams';

export const baseEmailData: EmailDetailInterface = {
  name: '',
  description: '',
  channel: {
    key: ChannelKeyEnum.EMAIL
  },
  sender: {
    id: '',
    email: ''
  },
  updated_at: Date.now(),
  status: ChannelStatusEnum.ACTIVE,
  type: CampaignTypeEnum.AUTOMATED,
  attachment: [],
  template: {
    subject: '',
    content: '',
    content_json: emptyState,
    preheader: '',
    category: '',
    width: '',
    last_status_change: Date.now(),
    precodings: [],
    precoding_example: []
  },
  example_code: {
    curl: 'curl'
  },
  campaign_id: ''
};

function TestEmail() {
  const { channelId, campaignType, channelType } = useCampaignParams();
  const [emailSet, setEmailSet] = useState<Set<string>>(new Set<string>());
  const emailArray = useMemo(() => Array.from(emailSet), [emailSet]);
  const { data = baseEmailData, isLoading } = useGetEmailChannelDetail(channelId);
  const [inputValue, setInputValue] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const {
    content,
    precoding_example,
    width: configurationWidth,
    preheader,
    subject
  } = data.template;
  const htmlString = content;
  const width = parseTemplateWidth(configurationWidth);
  const { renderedHtml } = useGenerateHtmlContent({
    htmlString,
    width
  });

  const precodingMap = useMemo(() => precodingArrayToMap(precoding_example), [precoding_example]);

  const isValidEmail = (email: string) => {
    return /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email);
  };

  if (isLoading) return <Loader />;

  return (
    <div className="flex h-fit w-full flex-col gap-7 bg-neutral-50 px-7 py-8">
      <CampaignContentHeader
        headerTitle="Tes Email Konten Campaign"
        backUrl={`/${campaignType}/${channelType}/${channelId}`}
      >
        <ActionButton emails={emailArray} />
      </CampaignContentHeader>
      <div className="flex gap-6">
        <LeftSection
          testDataSet={emailSet}
          setTestDataSet={setEmailSet}
          input={inputValue}
          setInput={setInputValue}
          setErrorMessage={setErrorMessage}
          maximumTestReceiverError="Maksimum 10 ID"
          onSubmitValidation={() => {
            if (!!inputValue && isValidEmail(inputValue) === false) {
              setErrorMessage('Format email salah');
              return false;
            }
            return true;
          }}
        >
          <TextField
            data-testid="text_box_user"
            maxLength={1000}
            full
            showCounter
            label="Kepada"
            placeholder="Ketik Email"
            value={inputValue}
            onChange={(value) => {
              setInputValue(value);
              if (isValidEmail(inputValue)) setErrorMessage('');
            }}
            errorMessage={errorMessage}
            isInvalid={!!errorMessage}
          />
        </LeftSection>
        <div className="flex-1">
          <EmailContent
            emailCategory={data.template.category}
            emailSender={data.sender.email}
            emailSubject={parsePrecodingInString(subject, precodingMap)}
            emailPreheader={parsePrecodingInString(preheader, precodingMap)}
            renderedHtml={parsePrecodingInString(renderedHtml, precodingMap)}
            width={configurationWidth}
            campaignType={data.type}
            attachments={attachmentListToMap(data.attachment)}
          />
        </div>
      </div>
    </div>
  );
}

export default TestEmail;
