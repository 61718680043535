import { Navigate } from 'react-router-dom';
import { ProtectedLayout } from '../layouts/protected-layout';
import {
  NotFound,
  IndexPage,
  SSOCallbackPage,
  AutomatedCampaignAnalytics,
  WhatsAppConfig,
  TestChannel,
  PushNotificationConfig,
  EmailConfig,
  MPConfig,
  TaggingConfig,
  Privacy,
  Contact,
  Login,
  CampaignPage,
  TermAndConditions,
  Users,
  Broadcast,
  AuditPage,
  UnsubscribeEmail,
  Page403,
  DomainDetail,
  UnsubscribePage,
  CampaignApprovalPage,
  CampaignDetailPage,
  WaitForApprovalPage
} from '../pages';

import { ALL_CHANNEL_SCOPES, AuthActionScopeEnum } from '../shared/user.interface';
import { RouteFilterInterface } from '../utils/route';
import RedirectTraffic from '../pages/redirect-traffic';
import { logicalOperatorWrapper } from '../utils/logicalOperator';
import { ChannelOperationEnum } from '../pages/campaign-details';
import RedirectChannel from '../pages/redirect-channel';
import RedirectTrafficFromCC from '../pages/redirect-traffic/redirect-traffic-from-cc';

const { OR } = logicalOperatorWrapper;

export interface RouteInterface extends RouteFilterInterface {
  id: string;
  path: string;
  element?: React.ReactNode;
  children?: RouteInterface[];
}

interface RouterListProps {
  isCheckerMaker: boolean;
}

export const generateRouterList: (props: RouterListProps) => RouteInterface[] = ({
  isCheckerMaker
}) => {
  return [
    {
      id: '/sso-callback',
      path: '/sso-callback',
      element: <SSOCallbackPage />,
      children: [
        {
          id: ':to',
          path: ':to',
          element: <></>
        }
      ]
    },
    {
      id: '/automated-campaign',
      path: '/automated-campaign',
      element: <ProtectedLayout />,
      children: [
        {
          id: 'automated-campaign-campaign',
          path: '',
          element: <RedirectTraffic from="automated-campaign" to="campaign" />
        },
        {
          id: '*',
          path: '*',
          element: <RedirectTraffic from="automated-campaign" to="campaign" />
        }
      ]
    },
    // TODO: remove when CC has integrated the logic
    {
      id: '/redirect-channel',
      path: '/channel/:channelType/:campaignId',
      element: <RedirectChannel />,
      scope: ALL_CHANNEL_SCOPES.CHANNEL_READ
    },
    {
      id: '/campaign',
      path: '/campaign',
      element: <ProtectedLayout />,
      children: [
        {
          id: 'campaign-list',
          path: '',
          element: <RedirectTrafficFromCC from="campaign" to="campaign/manual" />
          // TODO: remove when CC has integrated the logic
          // element: <RedirectTraffic from="campaign" to="campaign/manual" />
        },
        {
          id: ':campaignType',
          path: ':campaignType',
          children: [
            {
              id: 'channel-list',
              path: '',
              element: <CampaignPage />,
              scope: AuthActionScopeEnum.CAMPAIGN_READ
            },
            {
              id: ':channelType',
              path: ':channelType',
              children: [
                {
                  id: 'create-channel',
                  path: '',
                  element: <CampaignDetailPage operation={ChannelOperationEnum.CREATE} />
                },
                {
                  id: ':channelId',
                  path: ':channelId',
                  children: [
                    {
                      id: 'channel-detail',
                      path: '',
                      element: <CampaignDetailPage operation={ChannelOperationEnum.READ} />
                    },
                    {
                      id: 'broadcast',
                      path: 'broadcast',
                      element: <Broadcast />,
                      scope: ALL_CHANNEL_SCOPES.BROADCAST_READ
                    },
                    {
                      id: 'analytics',
                      path: 'analytics',
                      element: <AutomatedCampaignAnalytics />,
                      scope: ALL_CHANNEL_SCOPES.ANALYIC_READ
                    },
                    {
                      id: 'test',
                      path: 'test',
                      element: <TestChannel />,
                      scope: ALL_CHANNEL_SCOPES.CHANNEL_TEST
                    },
                    {
                      id: 'edit',
                      path: 'edit',
                      element: <CampaignDetailPage operation={ChannelOperationEnum.WRITE} />
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          id: 'waiting-for-approval',
          path: 'waiting-for-approval',
          element: <WaitForApprovalPage />,
          scope: AuthActionScopeEnum.CAMPAIGN_READ,
          enable: isCheckerMaker
        }
      ]
    },
    {
      id: '/contact',
      path: '/contact',
      element: <ProtectedLayout />,
      scope: OR(AuthActionScopeEnum.CONTACT_READ, AuthActionScopeEnum.UNSUBSCRIBE_READ),
      children: [
        {
          id: 'list',
          path: 'list',
          element: <Contact />,
          scope: AuthActionScopeEnum.CONTACT_READ
        },
        {
          id: 'unsubscribe-email',
          path: 'unsubscribe-email',
          element: <UnsubscribeEmail />,
          scope: AuthActionScopeEnum.UNSUBSCRIBE_READ
        }
      ]
    },
    {
      id: '/user',
      path: '/user',
      element: <ProtectedLayout />,
      scope: AuthActionScopeEnum.USER_READ,
      children: [
        {
          id: 'user-detail',
          path: '',
          element: <Users />
        }
      ]
    },
    {
      id: '/audit',
      path: '/audit',
      element: <ProtectedLayout />,
      scope: AuthActionScopeEnum.AUDIT_READ,
      children: [
        {
          id: 'audit-detail',
          path: '',
          element: <AuditPage />
        }
      ]
    },
    {
      id: '/configuration',
      path: '/configuration',
      element: <ProtectedLayout />,
      scope: OR(
        ALL_CHANNEL_SCOPES.CONFIG_READ,
        AuthActionScopeEnum.CATEGORY_EMAIL_READ,
        AuthActionScopeEnum.CONFIG_UNSUBSCRIBE_READ,
        AuthActionScopeEnum.ORGANIZATION_SETTINGS
      ),
      children: [
        {
          id: 'label',
          path: 'label',
          element: <TaggingConfig />,
          scope: AuthActionScopeEnum.CAMPAIGN_LABEL_READ
        },
        {
          id: 'push-notification',
          path: 'push-notification',
          element: <PushNotificationConfig />,
          scope: AuthActionScopeEnum.CHANNEL_CONFIG_PN_READ
        },
        {
          id: 'whastapp',
          path: 'whatsapp',
          element: <WhatsAppConfig />,
          scope: AuthActionScopeEnum.CHANNEL_CONFIG_WA_READ
        },
        {
          id: 'email',
          path: 'email',
          scope: OR(
            AuthActionScopeEnum.CHANNEL_CONFIG_EMAIL_READ,
            AuthActionScopeEnum.CATEGORY_EMAIL_READ,
            AuthActionScopeEnum.CONFIG_UNSUBSCRIBE_READ
          ),
          children: [
            {
              id: 'email-detail',
              path: '',
              element: <EmailConfig />,
              scope: OR(
                AuthActionScopeEnum.CHANNEL_CONFIG_EMAIL_READ,
                AuthActionScopeEnum.CONFIG_UNSUBSCRIBE_READ,
                AuthActionScopeEnum.CATEGORY_EMAIL_READ
              )
            },
            {
              id: ':id',
              path: ':id',
              element: <DomainDetail />,
              scope: AuthActionScopeEnum.CHANNEL_CONFIG_EMAIL_READ
            }
          ]
        },
        {
          id: 'setup-mp',
          path: 'setup-mp',
          element: <MPConfig />,
          scope: AuthActionScopeEnum.CHANNEL_CONFIG_MP_READ
        },
        {
          id: 'campaign-approval',
          path: 'campaign-approval',
          element: <CampaignApprovalPage />,
          scope: AuthActionScopeEnum.ORGANIZATION_SETTINGS
        }
      ]
    },
    {
      id: '/',
      path: '/',
      element: <IndexPage />
    },
    {
      id: '/login',
      path: '/login',
      element: <Login />
    },
    {
      id: '/404',
      path: '/404',
      element: <NotFound />
    },
    {
      id: '/term-and-conditions',
      path: '/term-and-conditions',
      element: <ProtectedLayout allowGuest />,
      children: [
        {
          id: 'tnc',
          path: '',
          element: (
            <div className="mx-auto w-full max-w-7xl">
              <TermAndConditions />
            </div>
          )
        }
      ]
    },
    {
      id: '/privacy-policy',
      path: '/privacy-policy',
      element: <ProtectedLayout allowGuest />,
      children: [
        {
          id: 'privacy-policy',
          path: '',
          element: (
            <div className="mx-auto w-full max-w-7xl">
              <Privacy />
            </div>
          )
        }
      ]
    },
    {
      id: '/403',
      path: '/403',
      element: <ProtectedLayout allowGuest />,
      children: [
        {
          id: '403',
          path: '',
          element: <Page403 />
        }
      ]
    },
    {
      id: '/email/unsubscribe',
      path: '/email/unsubscribe',
      element: <UnsubscribePage />
    },
    {
      id: '*',
      path: '*',
      element: <Navigate to="/404" />
    }
  ];
};
