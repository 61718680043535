import { useQuery } from '@tanstack/react-query';
import { getUserAuthData } from '../../api/auth';
import { LoginResponseProps } from '../../shared/user.interface';
import { QUERY_KEYS_ENUM } from '../../static/query-keys.enum';

export const useGetUserAuthData = ({
  onSuccess
}: {
  onSuccess: (data: LoginResponseProps) => void;
}) => {
  return useQuery({
    queryKey: [QUERY_KEYS_ENUM.getUserAuth],

    queryFn: async () => {
      const data = await getUserAuthData();
      onSuccess(data);
      return data;
    },
    refetchOnReconnect: true,
    refetchOnWindowFocus: true
  });
};
