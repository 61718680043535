import { useSearchParams } from 'react-router-dom';
import useCampaignPageQuery, {
  UseCampaignPageQueryReturn
} from '../../hooks/campaign-query/useCampaignPageQuery';
import ConfirmationModal from '../../components/modal/confirmation-modal';
import { useAuth } from '../../hooks/useAuth';
import { ReactNode } from 'react';

export const TaggedFlowModals = (props: Readonly<UseCampaignPageQueryReturn>) => {
  const { userData } = useAuth();
  const {
    createCampaignAccessValidationModal,
    createCampaignUserValidationModal,
    redirectToSourcePage,
    redirectToLoginPage,
    closeModal,
    params
  } = props;

  return (
    <>
      <ConfirmationModal
        isDanger
        title={`${userData.email} belum dapat membuat campaign`}
        message="Silakan hubungi admin untuk info lebih lanjut."
        okText="OK"
        cancelText="Kembali ke SQECC"
        modalState={createCampaignAccessValidationModal.visible}
        confirmationCloseCancel={redirectToSourcePage}
        confirmationCloseOk={closeModal}
      />
      <ConfirmationModal
        isDanger
        title={`${params.email || 'pengguna'} belum dapat membuat campaign`}
        message="Silakan masuk ke akun SQEMP Anda untuk membuat campaign."
        okText="OK"
        cancelText="Masuk ke SQEMP"
        modalState={createCampaignUserValidationModal.visible}
        confirmationCloseCancel={redirectToLoginPage}
        confirmationCloseOk={closeModal}
      />
    </>
  );
};

interface TaggedFlowWrapperProps {
  children: ReactNode;
}

const TaggedFlowWrapper = ({ children }: Readonly<TaggedFlowWrapperProps>) => {
  const searchParams = useSearchParams();
  const taggedCampaign = useCampaignPageQuery({ searchParams });

  return (
    <>
      {children}
      <TaggedFlowModals {...taggedCampaign} />
    </>
  );
};

export default TaggedFlowWrapper;
