import { QUERY_KEYS_ENUM } from '../../static/query-keys.enum';
import { useQuery } from '@tanstack/react-query';
import { redirectTo404 } from '../../utils/error';
import { useNavigate } from 'react-router';
import { getPushNotificationChannel } from '../../api/automated-campaign';

interface OptionsInterface {
  enabled?: boolean;
}

export const useGetPushNotificationTemplate = (
  channelId: string,
  { enabled = true }: OptionsInterface = {}
) => {
  const navigate = useNavigate();
  return useQuery({
    queryKey: [QUERY_KEYS_ENUM.getPushNotificationChannel, channelId],
    queryFn: async () => {
      try {
        return await getPushNotificationChannel(channelId);
      } catch (error) {
        redirectTo404(navigate, error);
      }
    },
    enabled: !!channelId && enabled
  });
};
