import { AxiosError } from 'axios';
import { ErrorMessage } from '../../shared/errorMessage.enum';
import { AutomatedCampaignEnum } from '../../static/automated-campaign.enum';
import { HandleErrorAddInterface } from '../../shared/automated-campaign.interface';
import { HandleErrorEnum } from '../../static/add-channel-modal.enum';
import { HTTPErrorCode } from '../../shared/errorCode.enum';
import { getErrorCode } from '../../utils/error';

export function handleErrorLogin(error: AxiosError) {
  const status = getErrorCode(error);
  switch (status) {
    case HTTPErrorCode.UNAUTHORIZED:
      return ErrorMessage.InvalidCredentials;
    case HTTPErrorCode.BAD_REQUEST:
      return ErrorMessage.RequestError;
    default:
      return ErrorMessage.SystemError;
  }
}

export function handleError(error: AxiosError) {
  const status = getErrorCode(error);

  if (status === HTTPErrorCode.BAD_REQUEST) {
    return ErrorMessage.RequestError;
  }

  return ErrorMessage.SystemError;
}

export function handleErrorAutomatedCampaign({
  title,
  desc,
  type,
  toastMessage
}: HandleErrorAddInterface) {
  const { TooLongTitleChannel, TooLongChannelDesc, TooLongTittle, TooLongDesc } = ErrorMessage;
  const titleError = type === HandleErrorEnum.channel ? TooLongTitleChannel : TooLongTittle;
  const descError = type === HandleErrorEnum.channel ? TooLongChannelDesc : TooLongDesc;

  if (
    title?.length > AutomatedCampaignEnum.titleMaxLength ||
    desc?.length > AutomatedCampaignEnum.descMaxLength
  ) {
    if (title.length > AutomatedCampaignEnum.titleMaxLength) {
      toastMessage('error', titleError);
    }
    if (desc.length > AutomatedCampaignEnum.descMaxLength) {
      toastMessage('error', descError);
    }
    return true;
  } else {
    return false;
  }
}

export const handleAxiosError = (err: any, customMessage?: string) => {
  const axiosError = err as AxiosError<any>;
  let errorMessage = 'An unknown error occurred';

  if (axiosError?.response && axiosError?.response?.status >= 500) {
    errorMessage =
      axiosError.response?.data?.message ||
      'Terjadi kegagalan saat menampilkan data dari sistem. Mohon coba lagi.';
  } else if (axiosError?.response?.data) {
    errorMessage = axiosError.response.data.message || axiosError.message;
  } else if (axiosError.message) {
    errorMessage = axiosError.message;
  }

  if (customMessage) {
    errorMessage = customMessage;
  }

  return errorMessage;
};
