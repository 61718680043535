import { Button, Icon, Token } from '@squantumengine/horizon';
import { Alert } from 'antd';
import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useWhatsappEditorContext } from '../../context/whatsapp-editor-context';
import { useGetWhatsAppChannelDetail } from '../../hooks/automated-campaign-query/useGetWhatsAppChannelDetail';
import useModal from '../../hooks/useModal';
import { ChannelStatusEnum } from '../../shared/automated-campaign.interface';
import { AuthActionScopeEnum } from '../../shared/user.interface';
import Checker from '../checker';
import { setInitialEditorState } from '../rich-text-input/hooks/useInitialJson';
import ScopeWrapper from '../role-wrapper/scope-wrapper';
import ModalEdit from '../whatsapp-editor/components/section/modal-edit';
import UneditableTemplateModal from '../whatsapp-editor/components/section/uneditable-template-modal';
import WhatsAppAuthPreview from '../whatsapp-editor/components/whatsapp-auth-preview';
import { default as WhatsappContentPreview } from '../whatsapp-editor/components/whatsapp-content';
import { useHeaderEditHelper } from '../whatsapp-editor/helper/header-edit.helper';
import { setInitialValue } from '../whatsapp-editor/helper/setInitialValue';
import CampaignDetailsTemplate from './campaign-details-template';
import { ChannelKeyEnum } from '../../shared/master-api.interface';
import { isEditButtonEnabled, isTestButtonEnabled } from '../../utils/automatedCampaign';
import useCampaignParams from '../../hooks/useCampaignParams';
import { useGetChannelList } from '../../hooks/automated-campaign-query/useGetChannelList';

function WhatsappContent() {
  const { channelId, campaignType, channelType } = useCampaignParams();
  const { data: dataChannel, isLoading } = useGetWhatsAppChannelDetail(channelId);
  const { state, setState, setThumbnail, initPrecodingMap, headerEditor, bodyEditor } =
    useWhatsappEditorContext();

  const { campaign_id: campaignId } = dataChannel ?? {};

  const { data: channelListData } = useGetChannelList(campaignId ?? '');

  const navigate = useNavigate();
  const { visible, closeModal, openModal, handleTestTemplate, toastMessage, setReadOnly } =
    useHeaderEditHelper();
  const editableModal = useModal();

  const now = useMemo(() => dayjs(), []);
  const canEditDate = useMemo(
    () => state.lastStatusChange.add(24, 'hours'),
    [state.lastStatusChange]
  );

  const enableTemplate = useCallback(() => {
    setReadOnly(false);
    setState((prev) => ({
      ...prev,
      disabledBtnEdit: false
    }));
  }, [setState, setReadOnly]);

  const onEdit = useCallback(() => {
    navigate(`/campaign/${campaignType}/${channelType}/${channelId}/edit`);
  }, [campaignType, channelType, channelId, navigate]);

  const isActive = state.status === ChannelStatusEnum.ACTIVE;

  const testButtonEnabled = isTestButtonEnabled(state.status);
  const editButtonEnabled = isEditButtonEnabled(state.status);

  const setInitialDetailWhatsapp = useCallback(() => {
    if (dataChannel) {
      setState((prev) => ({
        ...prev,
        isUnEdited: true,
        status: dataChannel.status
      }));
      setInitialValue({ dataChannel, setState, setThumbnail });
      initPrecodingMap(dataChannel.template.precoding_example);
    }
  }, [dataChannel, initPrecodingMap, setState, setThumbnail]);

  useEffect(() => {
    const { header_json, content_json } = state;
    header_json && setInitialEditorState(headerEditor, header_json);
    content_json && setInitialEditorState(bodyEditor, content_json);
  }, [state, headerEditor, bodyEditor]);

  useEffect(() => {
    setInitialDetailWhatsapp();
  }, [setInitialDetailWhatsapp]);

  return (
    <CampaignDetailsTemplate
      status={state.status}
      channelType={ChannelKeyEnum.WHATSAPP}
      headerTitle={dataChannel?.name ?? 'Detail WhatsApp Konten Campaign'}
      broadcastCheckerPrefix="whatsappDetail"
      isLoading={isLoading}
      contentDetail={{
        title: dataChannel?.name,
        description: dataChannel?.description || '-',
        senderNumber: dataChannel?.sender.contact,
        category: dataChannel?.template.subject,
        channelList: channelListData ?? [],
        campaignId: campaignId
      }}
      actionButtons={
        <>
          {editButtonEnabled && (
            <ScopeWrapper scope={AuthActionScopeEnum.CHANNEL_WA_WRITE}>
              <Button
                variant="secondary"
                className="min-w-52"
                disabled={
                  now.isBefore(canEditDate) || state.status === ChannelStatusEnum.WAITING_REVIEW
                }
                onClick={() => {
                  if (state.status === ChannelStatusEnum.WAITING_REVIEW)
                    return toastMessage(
                      'error',
                      'Template yang sedang ditinjau tidak bisa di edit'
                    );

                  if (!isActive) return enableTemplate();

                  now.isBefore(canEditDate) ? editableModal.openModal() : openModal();
                }}
              >
                Edit
              </Button>
            </ScopeWrapper>
          )}
          {testButtonEnabled && (
            <ScopeWrapper scope={AuthActionScopeEnum.CHANNEL_WA_TEST}>
              <Button data-testid="btn_send_test" variant="primary" onClick={handleTestTemplate}>
                Tes WhatsApp
              </Button>
            </ScopeWrapper>
          )}
        </>
      }
    >
      <div
        data-testid="manual-whatsapp-detail-page"
        className="flex min-h-96 min-w-[290px] max-w-[290px] flex-col rounded-lg bg-whatsapp p-4"
      >
        <Checker anchorPrefix="whatsapp" isDynamic>
          {state.selectedCategory === 'AUTHENTICATION' ? (
            <WhatsAppAuthPreview />
          ) : (
            <WhatsappContentPreview />
          )}
        </Checker>
      </div>
      {now.isBefore(canEditDate) && (
        <Alert
          showIcon
          className="flex gap-2 p-3"
          type="warning"
          message={<b className="text-base">Konten belum dapat diedit</b>}
          icon={<Icon name="exclamation-triangle" color={Token.COLORS.yellow[500]} />}
          description={
            <span>
              Anda baru dapat mengedit konten ini pada{' '}
              <b>{canEditDate.format('D MMMM YYYY pukul HH:mm')}</b>. Setelah itu, konten akan
              dikirim ke Meta untuk ditinjau.
            </span>
          }
        />
      )}
      <ModalEdit open={visible} onCancel={closeModal} onClickContinue={onEdit} />
      <UneditableTemplateModal
        open={editableModal.visible}
        editableDate={canEditDate}
        onClose={editableModal.closeModal}
      />
    </CampaignDetailsTemplate>
  );
}
export default WhatsappContent;
