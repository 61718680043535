import axiosAuthInstance from '../config/axios/axiosAuthInstance';
import axiosInstance from '../config/axios/axiosWithToken';
import {
  InviteUserInterface,
  FetchUserListPayloadInterface,
  FetchUserListResponseInterface,
  LogoutPayloadInterface,
  SSOLoginPayload,
  LoginRequestProps,
  LoginResponseProps,
  FetchEditUserLinkResponseInterface,
  FetchEditPermissionLinkResponseInterface
} from '../shared/user.interface';

export const loginRequest: (payload: LoginRequestProps) => Promise<LoginResponseProps> = async (
  payload
) => {
  const response = await axiosAuthInstance.post(`login`, payload);
  return response.data;
};

export const ssoLoginRequest: (payload: SSOLoginPayload) => Promise<LoginResponseProps> = async (
  payload
) => {
  const response = await axiosAuthInstance.post(`sso`, payload);
  return response.data;
};

export const logoutRequest: (payload: LogoutPayloadInterface) => Promise<any> = async (payload) => {
  const response = await axiosInstance.post(`auth/logout`, payload);
  return response.data;
};

export const inviteNewUser: () => Promise<InviteUserInterface> = async () => {
  const response = await axiosInstance.get(`auth/user/invite`);
  return response.data;
};

export const fetchUserList: (
  payload: FetchUserListPayloadInterface
) => Promise<FetchUserListResponseInterface> = async (payload) => {
  const response = await axiosInstance.get(
    `auth/user?page=${payload.page}&limit=${payload.limit}&q=${payload.query}&role=${payload.role}`
  );
  return response.data;
};

export const getUpdateUserPermissionLink: (
  username: string
) => Promise<FetchEditUserLinkResponseInterface> = async (username) => {
  const response = await axiosInstance.get(
    `auth/user/permissions/edit?target_username=${username}`
  );
  return response.data.data;
};

export const getUpdatePermissionLink: () => Promise<FetchEditPermissionLinkResponseInterface> =
  async () => {
    const response = await axiosInstance.get(`role/permissions/edit`);
    return response.data.data;
  };

export const deleteUser: (username: string) => Promise<void> = async (username) => {
  await axiosInstance.post(`auth/delete-user`, { target_username: username });
};

export const getUserAuthData: () => Promise<LoginResponseProps> = async () => {
  const response = await axiosInstance.get(`auth/me`);
  return response.data;
};
