import axios from 'axios';
import {
  datadogRequestInterceptor,
  onFulfilledDefaultDatadogInterceptor,
  onRejectedDefaultDatadogInterceptor
} from '../datadog/axiosInterceptor';

const axiosAuthInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/v1/auth/`,
  headers: {
    'Content-Type': 'application/json'
  }
});

const axiosRequestInterceptor = async (request: any) => {
  request = datadogRequestInterceptor(request);
  return request;
};

axiosAuthInstance.interceptors.response.use(
  onFulfilledDefaultDatadogInterceptor,
  onRejectedDefaultDatadogInterceptor
);

axiosAuthInstance.interceptors.request.use(axiosRequestInterceptor);

export default axiosAuthInstance;
