import axiosInstance from '../config/axios/axiosWithToken';
import {
  PayloadEditChannelInterface,
  ResponseAddChannelInterface,
  ResponseCampaignDetailInterface,
  ResponseChannelListInterface,
  ResponseEditAutomateCampaignInterface,
  ResponseEditChannelInterface,
  ResponseSenderInterface,
  FetchEmailTemplateResponseInterface,
  ChannelDetailInterface,
  ChannelListParamsInterface,
  CreateEmailChannelPayload,
  SenderListPayload,
  ResponseSenderListInterface,
  EmailDetailResponseInterface,
  ResponseCampaignDeleteInterface
} from '../shared/automated-campaign.interface';
import {
  ListCampaignProps,
  AddAutomatedCampaignRequest,
  AddAutomatedCampaignResponse
} from '../shared/campaign.interface';
import {
  PushNotificationChannelPayloadInterface,
  PushNotificationChannelResponseInterface
} from '../shared/push-notification-configuration.interface';

export const fetchAutomatedCampaignList: (params: string) => Promise<ListCampaignProps> = async (
  params
) => {
  const response = await axiosInstance.get(`campaign${params}`);
  return response.data;
};

export const fetchAutomatedCampaignDetail: (
  id: string
) => Promise<ResponseCampaignDetailInterface> = async (id) => {
  const response = await axiosInstance.get(`/campaign/${id}`);
  return response.data;
};

export const addNewAutomatedCampaign: (
  payload: AddAutomatedCampaignRequest
) => Promise<AddAutomatedCampaignResponse> = async (payload) => {
  const response = await axiosInstance.post(`campaign`, payload);
  return response.data;
};

export const fetchListSender: (id: string) => Promise<ResponseSenderInterface> = async (id) => {
  const response = await axiosInstance.get(`campaign/${id}/sender`);
  return response.data;
};

export const createEmailChannel: (
  payload: CreateEmailChannelPayload
) => Promise<ResponseAddChannelInterface> = async (payload) => {
  const response = await axiosInstance.post(`email/channel`, payload);
  return response.data;
};

export const getListSender: (
  payload: SenderListPayload
) => Promise<ResponseSenderListInterface> = async ({ page, limit = 10 }) => {
  const response = await axiosInstance.get(`email/sender?page=${page}&limit=${limit}`);
  return response.data;
};

export const editEmailChannel: (
  id: string,
  payload: CreateEmailChannelPayload
) => Promise<ResponseAddChannelInterface> = async (id, payload) => {
  const response = await axiosInstance.patch(`email/channel/${id}`, payload);
  return response.data;
};

export const fetchChannelDetail: (id: string) => Promise<ChannelDetailInterface> = async (id) => {
  const response = await axiosInstance.get(`channel/${id}`);
  return response.data;
};

export const fetchEmailChannel: (id: string) => Promise<EmailDetailResponseInterface> = async (
  id
) => {
  const response = await axiosInstance.get(`email/channel/${id}`);
  return response.data;
};

export const fetchChannelList: (
  id: string,
  params?: ChannelListParamsInterface
) => Promise<ResponseChannelListInterface> = async (id, params) => {
  const response = await axiosInstance.get(`campaign/${id}/channel`, { params });
  return response.data;
};

export const editAutomatedCampaign: (
  id: string,
  payload: any
) => Promise<ResponseEditAutomateCampaignInterface> = async (id, payload) => {
  const response = await axiosInstance.patch(`campaign/${id}`, payload);
  return response.data;
};

export const editAutomatedCampaignChannel: (
  payload: PayloadEditChannelInterface
) => Promise<ResponseEditChannelInterface> = async (payload) => {
  const { id, ...rest } = payload;
  const response = await axiosInstance.patch(`channel/${id}`, { ...rest });
  return response.data;
};

export const fetchEmailTemplate: (
  channelId: string
) => Promise<FetchEmailTemplateResponseInterface> = async (channelId) => {
  const response = await axiosInstance.get(`channel/${channelId}/template/email`);
  return response.data;
};

export const createPushNotificationChannel: (
  payload: PushNotificationChannelPayloadInterface
) => Promise<PushNotificationChannelResponseInterface> = async (payload) => {
  const response = await axiosInstance.post(`push_notification/channel`, payload);
  return response.data;
};

export const getPushNotificationChannel: (
  channelId: string
) => Promise<PushNotificationChannelResponseInterface> = async (channelId) => {
  const response = await axiosInstance.get(`push_notification/channel/${channelId}`);
  return response.data;
};

export const editPushNotificationChannel: (
  channelId: string,
  payload: PushNotificationChannelPayloadInterface
) => Promise<PushNotificationChannelResponseInterface> = async (channelId, payload) => {
  const response = await axiosInstance.patch(`push_notification/channel/${channelId}`, payload);
  return response.data;
};

export const deleteCampaign: (id: string) => Promise<ResponseCampaignDeleteInterface> = async (
  id
) => {
  const response = await axiosInstance.delete(`/campaign/${id}`);
  return response.data;
};
