import { useCallback, useEffect, useState } from 'react';
import createDOMPurify from 'dompurify';
import { UseGenerateHtmlContentInterface } from '../shared/generate-html.interface';

createDOMPurify.addHook('afterSanitizeAttributes', function (node) {
  // set all elements owning target to target=_blank
  if ('target' in node) {
    node.setAttribute('target', '_blank');
    node.setAttribute('rel', 'noopener');
  }
});

export const useGenerateHtmlContent = ({ htmlString, width }: UseGenerateHtmlContentInterface) => {
  const [renderedHtml, setRenderedHtml] = useState<string>('');
  const generateHTMLContent = useCallback(() => {
    const sanitizedHtml = createDOMPurify.sanitize(htmlString);

    const widthStyle = width ? `${width}px` : '100%';
    const htmlContent = `
    <div data-testid="email-preview-modal" style="width:${widthStyle};box-sizing:border-box;margin:0 auto;word-wrap: break-word;max-width:100%;">
      ${sanitizedHtml}
    </div>
    `;
    // hide preheader that is received from BE
    const htmlWithoutPreheader = htmlContent.replaceAll(
      'class="preheader"',
      'class="preheader" style="display: none; max-height: 0; overflow: hidden; opacity: 0; visibility: hidden;"'
    );
    setRenderedHtml(htmlWithoutPreheader);
  }, [htmlString, width]);

  useEffect(() => {
    generateHTMLContent();
  }, [generateHTMLContent]);

  return { renderedHtml };
};
