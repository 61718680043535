import { Drawer } from 'antd';
import CloseIcon from '../../assets/icons/close-icon';
import './campaign-details-drawer.css';
import { useCampaignDetailContext } from '../campaign-detail/context/campaign-detail-context';
import CommentCard from '../../components/card/comment-card';
import { Paragraph, SelectMenu } from '@squantumengine/horizon';
import { useMemo, useState } from 'react';
import { CommentListEnum } from '../../static/comment-list.enum';
import { useCheckerContext } from '../../components/checker/checker-context';
import { useGetCommentListInfinite } from '../../hooks/checker-maker-query/useGetCommentList';
import { CommentStatusEnum } from '../../static/cheker-maker.enum';
import useInfiniteScrollDropdown from '../../hooks/useInfiniteScrollDropdown';
import { CommentInterface } from '../../shared/checker-maker.interface';

const CampaignDetailsDrawer = () => {
  const [category, setCategory] = useState(CommentListEnum.ALL);
  const { openDrawer, handleCloseDrawer } = useCampaignDetailContext();
  const { reviewId, removeComment, resolveComment } = useCheckerContext();
  const commentData = useGetCommentListInfinite({
    id: reviewId!,
    page: 1,
    limit: 50
  });
  const { handleScroll: handleScrollComments, data: commentList } =
    useInfiniteScrollDropdown<CommentInterface>(commentData);

  const openComments = useMemo(
    () => commentList.filter((item) => item.status === CommentStatusEnum.OPEN),
    [commentList]
  );

  const resolvedComments = useMemo(
    () => commentList.filter((item) => item.status === CommentStatusEnum.RESOLVED),
    [commentList]
  );

  const options = [
    { label: 'Semua Komen', value: CommentListEnum.ALL },
    { label: 'Terbuka', value: CommentListEnum.OPEN },
    { label: 'Terselesaikan', value: CommentListEnum.FINISHED }
  ];

  return (
    <Drawer
      open={openDrawer}
      width={300}
      closable={false}
      zIndex={20}
      classNames={{
        header: 'border-0 mt-[72px] pt-[32px]',
        body: 'pt-0 overflow-hidden pr-0'
      }}
      title={
        <div className="flex items-center justify-between gap-1 text-base font-bold">
          <span>Komentar</span>
          <div onClick={handleCloseDrawer} className="cursor-pointer">
            <CloseIcon />
          </div>
        </div>
      }
    >
      <div
        className="flex max-h-[calc(100vh-170px)] flex-col gap-6 overflow-auto pr-6"
        onScroll={handleScrollComments}
      >
        <SelectMenu
          selectMenuStyle="w-[150px] border-solid border-neutral-300 hover:border-neutral-500"
          placeholder="Kategori"
          value={{
            value: category,
            label: options.find((option) => option.value === category)?.label ?? category
          }}
          options={options}
          onChange={(option: any) => setCategory(option.value)}
        />
        <div>
          {(category === CommentListEnum.ALL || category === CommentListEnum.OPEN) && (
            <div className="flex flex-col gap-4">
              <p className="m-0 font-semibold">Terbuka</p>
              <div className="flex flex-col gap-2">
                {openComments.length ? (
                  openComments.map((comment) => (
                    <CommentCard
                      key={comment.id}
                      open
                      comment={comment.text}
                      creator={comment.creator}
                      createdAt={comment.created_at}
                      highlight={comment.highlight}
                      onDelete={() => {
                        removeComment(comment.anchor, comment.id);
                      }}
                      onResolved={() => {
                        resolveComment(comment.id);
                      }}
                    />
                  ))
                ) : (
                  <Paragraph className="m-0">Tidak ada komentar</Paragraph>
                )}
              </div>
            </div>
          )}
          {(category === CommentListEnum.ALL || category === CommentListEnum.FINISHED) && (
            <div className="mt-4 flex flex-col gap-4">
              <p className="m-0 font-semibold">Terselesaikan</p>
              <div className="flex flex-col gap-2">
                {resolvedComments.length ? (
                  resolvedComments.map((comment) => (
                    <CommentCard
                      key={comment.id}
                      comment={comment.text}
                      createdAt={comment.created_at}
                      creator={comment.creator}
                      highlight={comment.highlight}
                    />
                  ))
                ) : (
                  <Paragraph className="m-0">Tidak ada komentar</Paragraph>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </Drawer>
  );
};

export default CampaignDetailsDrawer;
