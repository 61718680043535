import { useMemo } from 'react';
import { WhatsAppButtonInterface } from './section/button/button.interface';
import { getButtonPreview } from './section/button/button.helper';
import { useWhatsappEditorContext } from '../../../context/whatsapp-editor-context';

const BUTTON_LIMIT = 3;

interface WhatsAppButtonContentCardProps {
  button?: WhatsAppButtonInterface;
}

function ButtonContentCard({ button }: Readonly<WhatsAppButtonContentCardProps>) {
  const buttonData = getButtonPreview(button);
  const { icon: Icon, text } = buttonData;

  return (
    <div className="flex h-[34px] w-full items-center justify-center gap-1 border-0 border-t border-solid border-grey-50 text-blue-star-light first:border-t-0">
      <span className="h-3 w-3">
        <Icon />
      </span>
      <span className="truncate">{text}</span>
    </div>
  );
}

function WhatsAppButtonContent() {
  const { buttons } = useWhatsappEditorContext();

  const collapseButton = useMemo(() => buttons.length > BUTTON_LIMIT, [buttons]);
  const renderedButtonList = useMemo(
    () => (collapseButton ? buttons.slice(0, BUTTON_LIMIT - 1) : buttons),
    [buttons, collapseButton]
  );

  if (!buttons.length) return null;

  return (
    <div className="flex flex-col border-0 border-t border-solid border-grey-50 px-2">
      {renderedButtonList.map((button, index) => (
        <ButtonContentCard key={index} button={button} />
      ))}
      {collapseButton && <ButtonContentCard />}
    </div>
  );
}

export default WhatsAppButtonContent;
